/*=========================
  4.16 Saved cards Page CSS start
==========================*/

.card-box {
    border-radius: 15px;
    padding: 25px;
    position: relative;
    overflow: hidden;
    [dir="rtl"] & {
        direction: rtl;
    }
    &:after {
        content: "";
        position: absolute;
        right: -50px;
        width: 70%;
        height: 100%;
        top: 0;
        z-index: 1;
        background: linear-gradient(0deg, #000000 2.3%, #FFFFFF 177.41%);
        background-blend-mode: screen;
        mix-blend-mode: screen;
        opacity: 0.3;
        transform: skew(30deg);
    }
    &.blue-card  {
        background: linear-gradient(0deg, #ADD8E6 -3.73%, #056787 106.54%);
        box-shadow: 0px 3px 3px rgba(130, 187, 205, 0.46);
    }
    &.yellow-card {
        background: linear-gradient(0deg, #FFD094 -3.73%, #9B7C2B 106.54%);
        box-shadow: 0px 3px 3px rgba(223, 181, 114, 0.46);
    }
    &.pink-card {
        background: linear-gradient(0deg, rgba(84, 80, 83, 0.8) -3.73%, rgba(255, 0, 214, 0.2) 106.54%);
        box-shadow: 0px 3px 3px rgba(201, 96, 170, 0.46);
    }
    .card-top {
        display: flex;
        align-items: center;
        h4 {
            text-transform: capitalize;
            color: $white;
            font-weight: 700;
            [class="dark"] & {
                color: #ffffff;
            }
        }
    }
    .chip-img {
        margin: 12px 0;
    }
    .card-number {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 22px;
        span {
            font-size: 18px;
            font-weight: 700;
            color: $white;
            [class="dark"] & {
                color: #ffffff;
            }
        }
    }
    .card-bottom {
        display: flex;
        h4 {
            font-weight: 700;
            color: $white;
            text-transform: uppercase;
            [class="dark"] & {
                color: #ffffff;
            }
        }
        .valid-threw {
            display: flex;
            align-items: center;
            margin-left: auto;
            [dir="rtl"] & {
                margin-right: auto;
                margin-left: unset;
            }
            span {
                font-size: 5px;
                margin-right: 8px;
                color: $white;
                [dir="rtl"] & {
                    margin-right: 0;
                    margin-left: 8px;
                }
                [class="dark"] & {
                    color: #ffffff;
                }
            }
        }
        h6 {
            font-size: 12px;
            font-weight: 700;
            color: $white;
            [class="dark"] & {
                color: #ffffff;
            }
        }
    }
}



.save-card-section {
    .slick-default {
        .slick-list {
            margin-left: -35px;
        }
    }
    .slick-slide {
        opacity: 0.3;
        transition: all 0.5s ease;
    }
    .slick-center {
        opacity: 1;
        transition: all 0.5s ease;
    }
    .card-buttons {
        margin-top: 15px;
        a {
            font-size: 14px;
            color: $content-color;
            text-transform: uppercase;
            background-color: $grey-light-60;
            padding: 4px 10px;
            border-radius: 3px;
            margin: 0 5px;
            display: inline-block;
        }
    }
}


.wallet-link-section {
    ul {
        li {
            background-color: $grey-light-40;
            border-radius: 3px;
            display: flex;
            align-items: center;
            padding: 16px;
            img {
                width: 40px;
                margin-right: 15px;
                [dir="rtl"] & {
                    margin-right: 0;
                    margin-left: 15px;
                }
            }
            .content {
                h4 {
                    font-weight: 700;
                    margin-bottom: 2px;
                }
                h6 {
                    color: $content-color;
                    span {
                        color: $title-color;
                    }
                }
            }
            a {
                color: $theme-color;
                margin-left: auto;
                font-weight: 700;
                [dir="rtl"] & {
                    margin-right: auto;
                    margin-left: 0;
                }
            }
            + li {
                margin-top: 15px;
            }
        }
    }
}