/*=====================
  2.5  FAQ CSS start
==========================*/
.faq-section {
    .accordion-item {
        border: none;
        margin-top: 10px;
    }
    .accordion-button {
        background-color: $grey-light-60;
        color: $title-color;
        line-height: 1.4;
        &:not(.collapsed) {
            &:after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            }
        }
        &:focus {
            border-color: transparent;
            box-shadow: none;
        }
        &:after {
            background-size: 0.90rem;
            transform: rotate(270deg);
            margin-top: -6px;
            margin-right: -10px;
            filter: invert(1);
            [dir="rtl"] & {
                margin-right: auto;
            }
        }
    }
}