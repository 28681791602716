/*=====================
   1.2 Typography CSS start
==========================*/

body {
  font-family: Lato, sans-serif;
  position: relative;
  font-size: 14px;
  margin: 0;
  color: $title-color;
  background-color: $white;
  &::-webkit-scrollbar {
    width: 0; 
    background: transparent; 
  }
}

html {
  scroll-behavior: smooth;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
  [dir="rtl"] & {
    padding-inline-start: 0;
    margin-inline-start: 0;
  }
}

li {
  display: inline-block;
  font-size: 14px;
  color: $title-color;
}

p {
  font-size: 14px;
  line-height: 1.3;
  color: $title-color;
}

a {
  transition: 0.5s ease;
  text-decoration: none;
  color: $title-color;
  &:hover {
    text-decoration: none;
    transition: 0.5s ease;
  }
  &:focus {
    outline: none;
  }
}

:focus {
  outline: none;
}

.form-control {
  font-size: 14px;
  color: $title-color;
  [dir="rtl"] & {
    text-align: right;
  }
  &:focus {
    box-shadow: none;
  }
}

h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
  text-transform: capitalize;
  margin: 0;
  color: $title-color;
}

h2 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  margin: 0;
  color: $title-color;
}

h3 {
  font-size: 15px;
  line-height: 1.2;
  margin: 0;
  color: $title-color;
}

h4 {
  font-size: 14px;
  line-height: 1.3;
  margin: 0;
  color: $title-color;
}

h5 {
  font-size: 13px;
  line-height: 1.3;
  margin: 0;
  color: $title-color;
}

h6 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin: 0;
  color: $title-color;
}

span {
  font-size: 12px;
  color: $title-color;
}

.title {
  margin-bottom: 15px;
  margin-top: -5px;
}
