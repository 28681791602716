/**=====================
  2.11 Switch css start
==========================**/

.toggle-sec {
    display: flex;
    align-items: center;
    label {
        margin-right: 20px;
        [dir="rtl"] & {
            margin-right: 0;
            margin-left: 20px;
        }
        span {
            display: block;
            color: #647589;
        }
    }
    .toggle-btn {
        position: relative;
        width: 74px;
        height: 36px;
        overflow: hidden;
        border-radius: 100px;
        margin-left: auto;
        [dir="rtl"] & {
            margin-right: auto;
            margin-left: 0;
        }
        .knobs,
        .layer {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: $grey-light-60;
            transition: 0.3s ease all;
            z-index: 1;
            left: 0;
        }
        .checkbox {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 3;
        }
        .knobs {
            z-index: 2;
            &:before,
            &:after,
            span {
                position: absolute;
                top: 4px;
                width: 28px;
                height: 28px;
                font-size: 10px;
                font-weight: bold;
                text-align: center;
                line-height: 1;
                padding: 9px 4px;
                border-radius: 50%;
                transition: 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
            }
            &:before {
                content: 'NO';
                left: 4px;
            }
            &:after {
                content: 'YES';
                right: -24px;
            }
            &:before,
            &:after {
                color: $white;
                z-index: 2;
                [class="dark"] & {
                    color: #ffffff;
                }
            }
            span {
                left: 4px;
                background-color: rgba($black, 0.6);
                z-index: 1;
            }
        }
        .checkbox {
            &:checked {
                +.knobs {
                    &:before {
                        left: -24px;
                    }
                    &:after {
                        right: 4px;
                    }
                    span {
                        left: 42px;
                        background-color: $theme-color;
                    }
                    ~.layer {
                        background-color:  $grey-light;
                    }
                }
            }
        }
    }
}