/*=========================
   profile Page CSS start
==========================*/

.profile-detail {
   background-color: $grey-light-60;
   padding: 25px 16px;
   .media {
      display: flex;
      align-items: center;
      img {
         width: 82px;
         height: 82px;
         border-radius: 100%;
         object-fit: cover;
         margin-right: 20px;
         [dir="rtl"] & {
            margin-right: 0;
            margin-left: 20px;
        }
      }
      .media-body {
         h6 {
            margin-bottom: 8px;
         }
         .edit-btn {
            background-color: $theme-color;
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            padding: 2px 10px;
            border-radius: 3px;
         }
      }
   }
}

.user-avtar-section {
   text-align: center;
   position: relative;
   img {
      width: 82px;
      height: 82px;
      border-radius: 100%;
   }
   .edit-icon {
      position: absolute;
      right: 40%;
      bottom: 2px;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      background-color: $white;
      box-shadow: $shadow-1;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
         color: $theme-color;
      }
   }
}

.detail-form-section {
   .form-floating {
      position: relative;
   }
   .change-btn {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      text-transform: uppercase;
      color: $theme-color;
      [dir="rtl"] & {
         right: unset;
         left: 16px;
     }
   }
}