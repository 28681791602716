/*=====================
  4.8 Home page CSS start
==========================*/

.top-space {
    margin-top: 54px;
    &.sm-space {
        margin-top: 40px;
    }
    &.lg-space {
        margin-top: 60px;
    }
    &.xl-space {
        margin-top: 72px;
    }
}

// top category
.category-section {
    padding-top: 0;
    overflow: hidden;
    .category-slide {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        &::-webkit-scrollbar {
            width: 0;  
            height: 0;
            background: transparent;  
        }
        li {
            display: inline-block;
            width: 84px;
            text-align: center;
            @media (max-width: 480px) {
                width: 74px;
            }
        }
    }
    .category-box {
        text-align: center;
        img {
            margin: 0 auto;
            width: 65px;
        }
        h4 {
            margin-top: 5px;
            text-transform: capitalize;
            color: $title-color;
            font-size: 14px;
            font-weight: 700;
        }
    }
}

// Home slider
.home-section {
    overflow: hidden;
    .slick-default {
        .slick-list {
            margin-left: -23px;
        }
    }
    .slider-box {
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        .slider-content {
            padding: 18px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            text-align: left;
            h1 {
                color: $theme-color;
                font-weight: 700;
                margin: 6px 0;
            }
            h2 {
                margin-top: -4px;
            }
            h6 {
                color: $title-color;
                margin-bottom: 8px;
                [class="dark"] & {
                    color:  #777777;
                }
            }
        }
    }
}

// deals section
.deals-section {
    margin-top: 10px;
    .title-part {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        h2 {
            font-weight: 700;
        }
        a {
            font-size: 14px;
            margin-left: auto;
            color: $theme-color;
            [dir="rtl"] & {
                margin-right: auto;
                margin-left: 0;
            }
        }
    }
    
}

// title section
.title-section {
    margin-bottom: 15px;
    h2 {
        margin-top: -5px;
    }
    h3 {
        color: $content-color;
    }
}

// banner section
.banner-timer {
    .banner-bg {
        background-color: $grey-light;
        padding: 25px;
        position: relative;
        .banner-content {
            h6 {
                color: $content-color;
            }
            h2 {
                color: $title-color;
                text-transform: capitalize;
            }
            a {
                color: $content-color;
                text-decoration: underline;
                text-transform: capitalize;
            }
        }
        .banner-img {
            position: absolute;
            right: 0;
            width: 38%;
            top: -20px;
            [dir="rtl"] & {
                right: unset;
                left: 0;
            }
            @media (max-width: 767px) {
                width: 44%;
            }
            @media (max-width: 576px) {
                width: 55%;
            }
            @media (max-width: 420px) {
                top: 10px;
                width: 61%;
            }
        }
    }
}

// brand section
.brand-section {
    overflow: hidden;
    .slick-default {
        .slick-list {
            margin-left: -38px;
        }
    }
    .brand-box {
        padding: calc(10px + (22 - 10) * ((100vw - 320px) / (1920 - 320))) calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
        background-color: $grey-light;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: calc(40px + (90 - 40) * ((100vw - 320px) / (1920 - 320)));
        img {
            [class="dark"] & {
                filter: invert(0.8);
            }
        }
        a {
            text-align: center;
            img {
                width: 70%;
            }
        }
        + li {
            margin-left: 15px;
        }
    }
}

// product slider
.product-slider-section {
    .slick-default {
        .slick-list {
            margin-left: -68px;
            @media (max-width: 360px) {
                margin-left: -28px;
            }
        }
    }
}

// offer box
.offer-corner-section {
    .offer-box {
        text-align: center;
        border-radius: 3px;
        position: relative;
        z-index: 1;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            z-index: -1;
            background-blend-mode: overlay;
            background-color: $grey-light-60;
            [class="dark"] & {
                background-image: none;
            }
        }
        a {
            display: block;
            width: 100%;
            padding: 12px 8px;
            font-size: 16px;
            color: $title-color;
            text-transform: capitalize;
            letter-spacing: 0.05em;
        }
    }
}
