/*========================
   4.3 Authentication CSS start
==========================*/

.form-section {
    h1 {
        font-size: 25px;
        margin-bottom: 24px ;
        line-height: 1.3;
    }
}

.or-divider {
    border-bottom: 1px solid $border-color;
    margin: 30px 0;
    position: relative;
    span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        padding: 2px 8px;
        background-color: $white;
        color: $content-color;
    }
}

.social-auth {
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            width: 50px;
            height: 50px;
            border-radius: 3px;
            background-color: $grey-light-60;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 6px;
            img {
                width: 26px;
            }
        }
    }
}

.password-hs {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    [dir="rtl"] & {
        right: unset;
        left: 15px;
    }
    i {
        font-size: 22px;
        color: $content-color;
    }
}

.input-otp-form {
    display: flex;
    align-items: center;
    input {
        width: 55px;
        height: 55px;
        text-align: center;
        border: none;
        background-color: $grey-light-60;
    }
    > * {
        margin-right: 10px;
    }
}