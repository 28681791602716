/*=========================
  4.18 Settings Page CSS start
==========================*/
.setting-section {
    .card {
        border: none;
        box-shadow: 0 0 8px #dcdcdc;
    }
    .form-check-label  {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        padding-left: 40px;
    }
}