/*=====================
  3.3 Productbox CSS start
==========================*/
.product-inline {
    background-color: $grey-light-40;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 10px;
    position: relative;
    .product-inline-content {
        width: 60%;
        padding-right: 10px;
        .price {
            h4 {
                font-weight: 400;
            }
        }
        h4 {
            margin-bottom: 3px;
            color: $title-color;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;
        }
        h5 {
            color: $content-color;
            margin-bottom: 3px;
        }
    }
    img {
        width: 90px;
        height: 90px;
        border-radius: 3px;
        object-fit: cover;
        margin-right: 12px;
        [dir="rtl"] & {
            margin-right: 0;
            margin-left: 12px;
        }
    }
}

.price {
    margin-top: 2px;
    h4 {
        margin-bottom: 0;
        del {
            color: $content-color;
            margin-left: 6px;
        }
        span {
            color: $theme-color;
            font-size: 14px;
            margin-left: 6px;
        }
    }
}

.wishlist-btn {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: absolute;
    top: 10px;
    right: 10px;
    box-shadow: 0px 2px 4px rgba($black, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    [dir="rtl"] & {
        left: 10px;
        right: unset;
    }
    .icli {
        color: $content-color;
    }
    .icbo {
        display: none;
    }
    &.active {
        .icli {
            display: none;
        }
        .icbo {
            display: block;
            color: $theme-color;
        }
        .effect:before {
            animation: fireworkLine 0.5s linear 0.1s;
        }
        .effect:after {
            animation: fireworkPoint 0.5s linear 0.1s;
        }
    }
}

.product-box {
    .img-part {
        box-shadow: 0px 2px 4px rgba($black, 0.1);
        border-radius: 3px;
        position: relative;
        overflow: hidden;
        label {
            font-size: 9px;
            font-weight: 700;
            color: $white;
            background-color: $theme-color;
            position: absolute;
            left: 8px;
            top: 10px;
            border-radius: 2px;
            text-transform: uppercase;
            padding: 3px 9px;
            [class="dark"] & {
                color: #ffffff;
            }
            [dir="rtl"] & {
                left: unset;
                right: 8px;
            }
        }
    }
    .product-content {
        margin-top: 8px;
        [dir="rtl"] & {
            text-align: right;
        }
        h4 {
            margin: 0;
            color: $title-color;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 700;
        }
        .price {
            h4 {
                font-weight: 400;
            }
        }
    }
}

.rating-section {
    display: flex;
    align-items: center;
    h6 {
        margin-left: 5px;
    }
}

.slick-slider {
    .ratings {
        [dir="rtl"] & {
            justify-content: flex-end;
            padding-right: 0;
        }
    }
}

.ratings {
    display: flex;
    align-items: center;
    li {
        .icbo {
            color: $ratings;
            &.empty {
                color: $border-color;
            }
        }
        + li {
            margin-left: 4px;
        }
    }
}