/*=========================
  4.12 Order Success Page CSS start
==========================*/

.order-success-section {
    text-align: center;
    h1 {
        margin-top: 26px;
        color: $theme-color;
        margin-bottom: 10px;
        font-size: 22px;
    }
    h2 {
        font-weight: 400;
    }
}