/*=========================
 4.14 Payment Page CSS start
==========================*/

.offer-listing {
    position: relative;
    &.maximized {
        ul {
         height: auto;
         transition: all 0.5s ease;
        }
        .overlay-offer {
            display: none;
        }
    }
    ul {
        height: 130px;
        overflow: hidden;
        transition: all 0.5s ease;
        li {
            display: block;
            font-size: 12px;
            color: $content-color;
            padding: 14px 16px;
            background-color: $grey-light-40;
            border-radius: 3px;
            + li {
                margin-top: 10px;
            }
        }
    }
    .overlay-offer {
        position: absolute;
        bottom: 0;
        left: 0;
        background: $gradient-4;
        width: 100%;
        height: 64px;
    }
}

.show-more {
    text-align: center;
    width: 100%;
    display: block;
    color: $theme-color;
    margin-top: 5px;
    &:hover, &:focus {
        color: $theme-color;
    }
}

.payment-method-section {
    .card {
        border: none;
        background-color: $white;
        + .card {
            margin-top: 12px;
        }
    }
    .card-header {
        border: none;
        background-color: $grey-light-45;
        padding: 10px 20px;
        .btn {
            padding: 0;
            width: 100%;
            text-decoration: none;
        }
        label {
            font-size: 16px;
            text-transform: capitalize;
            font-weight: 400;
            color: $title-color;
            display: flex;
            align-items: center;
            input {
                margin-left: auto;
                margin-right: 2px;
                [dir="rtl"] & {
                    margin-right: auto;
                    margin-left: 0;
                }
            }
        }
        img {
            width: 24px;
            margin-right: 13px;
            [class="dark"] & {
                filter: invert(1);
            }
            [dir="rtl"] & {
                margin-right: 0;
                margin-left: 13px;
            }
        }
    }
}